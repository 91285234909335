<template>
    <div>
        <rxNavBar  title="预定合同" androidOrIOSFlag="true"></rxNavBar>
        <!--搜索框-->
        <div class="head">
            <div class="search">
                <div class="search-icon"></div>
                <input type="text" placeholder="合同编号/房源地址/租客姓名、手机号" v-model="keywords"  @keyup.enter="searchSubmit">
            </div>
        </div>

        <div class="reviews">
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :finished-text="noContent? '':'已经到底了'"
                        @load="this.onLoad"
                >
                    <!--租房列表-->
                    <!--只显示perContractStatus-->
                    <div class="review" v-for="(item,index) in perContractlist" :key="index" v-if="item.perContractStatus==0||item.perContractStatus==4||item.perContractStatus==5">

                        <div class="title"  style=" border: none">
                            <!--图片部分-->
                            <div style="float:left;width: 130px;background: #C7C7C7;margin-top: 10px;margin-left: 5px;">
                                <div class="relation">
                                    <img :src="!item.path ?errorImg:item.path" class="relationImg"/>
                                </div>
                            </div>
                            <!--文字部分-->
                            <div style="float: left;width: 250px;margin-top: 7px;margin-right: 10px;margin-left: -13px">
                                <div class="hidden"><span style="color: #222222;margin-right: 3px">{{item.roomDetailedAddress}}</span></div>
                                <div style="margin-top: 8px">
                                    <span class="font">{{item.estateName}}</span>
                                    <span class="font">|</span>
                                    <span class="font">{{item.builtUpArea?item.builtUpArea:'未知'}}㎡</span>
                                    <span class="font">|</span>
                                    <span class="font">{{item.floor}}层</span>
                                </div>
                                <div style="margin-top: 8px">
                                    <span class="fnotRed">{{item.decorationTitle}}</span>
                                    <span class="fnotRed">{{item.rentMoney}}元/月</span>
                                </div>
                            </div>
                        </div>
                        <div class="panelInfo">
                            <p style="margin-top: 20px;margin-bottom: 5px;color: #A4A4A4;font-weight: normal;margin-left: 10px">预定人：<span>{{item.userName}}</span></p>
                            <p style="margin-top: 5px;margin-bottom: 5px;color: #A4A4A4;font-weight: normal;margin-left: 10px">定金：<span>{{item.earnestMoney}}元</span></p>
                            <p style="margin-top: 5px;margin-bottom: 5px;color: #A4A4A4;font-weight: normal;margin-left: 10px">预计入住：<span>{{item.earnestMoneyInvalidTime}}</span></p>
                            <p style="margin-top: 5px;margin-bottom: 20px;color: #A4A4A4;font-weight: normal;margin-left: 10px">租期：<span>{{item.rentTimeLong}}个月</span></p>
                        </div>

                        <div class="bottom">
                            <div class="time"></div>
                            <div @click="toCancelReservation(item.id,item.roomHouse_id)" v-if="item.perContractStatus==0||item.perContractStatus==4" class="cancelBtn">取消预订</div>
                            <div @click="signContract(item)" v-if="item.perContractStatus==0" class="check">签约</div>
                            <div @click="signReserveContract(item)" v-if="item.perContractStatus==4" class="check">预定签约</div>
                            <div v-if="item.perContractStatus==5" style="margin-top: 5px;margin-bottom: 20px;color: #A4A4A4;font-weight: normal;margin-left: 25px">预定合同已创建，请前往小程序签约</div>
                        </div>
                    </div>
                    <common-empty v-if="noContent"></common-empty>
                </van-list>
            </van-pull-refresh>

        </div>
<!--        新增预定按钮-->
        <!--<div class="addBtn">
            <button @click="skipNewReserve">新增合同</button>
        </div>-->

    </div>
</template>

<script>
    import {
        NavBar,
        Search ,
        DatetimePicker,
        List,
        PullRefresh,Toast
    } from 'vant';
    import Vue from 'vue';
    Vue.use(List,PullRefresh);
    import  {percontractqueyPerContractList,cancelReservation,perContractSign} from "../../../getData/getData";
    import {checkAndroid, checkIOS, getStaffId, globaluserId, responseUtil} from "../../../libs/rongxunUtil";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";



    export default {
        name: "preContract",
        components:{
            [NavBar.name]:NavBar,
            [Search.name]:Search,
            [DatetimePicker.name]: DatetimePicker,
            [List.name]: List,
            [PullRefresh.name]: PullRefresh,
            rxNavBar
        },

        data(){
            return{

                isShow:false,
                isWait:false,
                isAll:true,
                noContent:false,
                isExpectedDateShow: false,
                isExpectedDateShow01: false,
                isPotentialHousingTypeShow: false,
                isPotentialHousingTypeSelected: false,

                perContractlist: [],//
                loading: false,
                finished: false,
                currentPage:'1',//当前页
                pageCount:'',//总页数
                numberPage:'10',//每页条数
                count: 0,
                type:"0",
                isLoading: false,
                errorImg: 'https://himihome.oss-cn-qingdao.aliyuncs.com/images/platform/templatetmp/2589de77-4277-4be7-add9-f09f2b176e57.png',
                keywords:'',
                //循环的合同的参数
                contract:[],
            }

        },
        mounted: function () {
            this.userId= this.$route.query.userId
            this.percontractqueyPerContractList()
        },
        created() {

        },
        beforeRouteEnter(to, from, next) {
            //清除本地localStorage缓存
            if(from.name == null){
                localStorage.removeItem('currentLabel');
                localStorage.removeItem('type');
                localStorage.removeItem('inputValue');
                localStorage.removeItem('currentLabelPublic');
            }
            next()
        },

        methods:{
            //跳转新增预定合同
            skipNewReserve(){
                this.$router.push({
                    name : 'newReserve',
                    query:{
                        roomId:'3'
                    }
                })
            },
            //取消
            toCancelReservation(id,roomHouse_id){
				const that = this
				let initData = {}
				initData.perContractId = id
				initData.roomHouseId = roomHouse_id
				cancelReservation(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						if (response.data.code == 0) {
							Toast('取消成功')
                            that.onRefresh()
						}
					})
				})
			},

            //搜索按钮
            searchSubmit(){
                this.default();
                this.percontractqueyPerContractList()
            },
            //预定合同签约
            signReserveContract(item){
                let that =this;
                let _parms = {
                    id:item.id
                }
                perContractSign(_parms).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
						if (response.data.code == 0) {
							Toast('预定合同创建成功')
                            that.onRefresh()
						}                        
                    })
                })              
            }, 
            //签约
            signContract(item){
                console.log(item)
                let paid = 0
                let perContractId = item.id
                if(item.payType == '是' || item.payType == '1'){
                    paid = 1
                }
                this.$router.push({
                    name : 'NewContract',
                    query:{
                        mobile:item.glMobile,//电话号
                        type:'2',//预定标志
                        paid: paid,
                        perContractId: perContractId,
                        roomId:item.roomHouse_id,
                        prId:item.potentialResource_id,//潜在id
                        houseAddress:item.roomDetailedAddress,
                        signStaff_id:item.signStaff_id,
                        sinName:item.sinName,
                        signDepartment_id:item.signDepartment_id,
                        departmentName:item.departmentName
                    }
                })
            },
            //返回上一层
            leftReturn(){
                this.$router.go(-1);
            },
            onLoad:function(){//分页
                this.percontractqueyPerContractList()
            },
            onRefresh() {//刷新
                setTimeout(() => {
                    this.default();
                    this.loading = true;
                    this.finished = false
                    this.isLoading = false;
                    this.onLoad()
                    // Toast(this.msg);
                },200);
                responseUtil.alertMsg(this,"刷新成功")
            },
            default(){//默认值
                this.pageCount=''
                this.currentPage='1'
                this.perContractlist=[]
            },

            percontractqueyPerContractList(){ //预约合同列表接口
                let that = this;
                let queryPersonDetailsData={
                    currentPage:that.currentPage,
                    numberPage:that.numberPage,
                    keywords:that.keywords,
                    user_id :getStaffId(),
                    globaluser_id : that.userId,
                    perContractStatus : ''   //app 端固定查有效的
                }
                percontractqueyPerContractList(queryPersonDetailsData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if (response.data.code == 0){
							console.log(response.data.data)
                            that.pageCount=response.data.data.totalPage//总页数
                            var  pageCount=response.data.data.pageCount//总条数
                            var list=response.data.data.data
                            if (pageCount == 0 || that.pageCount==0 || !list){//没有数据时
                                that.loading=false
                                that.finished = true
                                that.noContent=true//显示空状态
                            }
                            if (list&&that.pageCount != 0){//有数据时
                                var currentPage=parseInt(that.currentPage);//当前页
                                var pages=that.pageCount;//总页数
                                if(pages >= currentPage){//当前页不大于总页数
                                    that.currentPage=currentPage+1
                                    for (let i=0;i<list.length;i++){
                                        that.perContractlist.push(list[i])
                                    }
                                    that.loading=false
                                    that.noContent=false//不显示空状态
                                    console.log("长度="+that.perContractlist.length)
                                }else {
                                    that.loading=false
                                    that.finished = true
                                }
                            }
                        }else if (response.data.code != 0) {
                            responseUtil.alertMsg(that,response.data.msg)
                            that.loading=false
                            that.finished = true
                        } else {
                            responseUtil.alertMsg(that,response.data.msg)
                            that.loading=false
                            that.finished = true
                        }


                    })
                })
            },


        }
    }
</script>

<style lang="less" scoped>
    @border:1px solid rgb(244, 244, 244);
    .title{
        width: 432px;
        height: 110px;

        font-weight: bolder;
        color: #324057;
        border-bottom:@border;
        font-size: 15px;
        position: relative;

    }

    /*超出隐藏部分div的css*/
    .hidden{
        width: 210px;
        height: 20px;
        font-weight: bold;
        font-family: PingFangSC-Semibold;
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        margin-top: 10px;
    }
    .relation{
        width: 100px;
        height: 75px;
        border-radius: 8px;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 12px;
        margin-top: 15px;
        top:0px;
    }
    .relationImg{
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }
    /*沙河口部分的css margin-right: 8px;font-size: 13px*/
    .font{
        margin-right: 8px;
        font-size: 12px;
        color: #979797;
    }
    .fnotRed{
        font-size: 13px;
        color: #F38771;
        margin-right: 5px;
    }
    .panelInfo{
        border-bottom:@border;
        border-top:@border;
        padding: 0 14px;
        font-size: 14px;
        p{
            font-weight: bolder;
            margin: 15px 0px;
            .fitment{
                color: #f00;
            }
            span{
                font-weight: normal;
                color: #A4A4A4;
            }
        }
    }
    .bottom .look{
        width: 90px;
        height: 32px;
        font-size: 14px;
        text-align: center;
        line-height: 32px;
        margin-left: 42px;

        color: #fe5e3a;
        border-radius: 8px;
        border: 1px solid transparent;
        background-image: linear-gradient(white,white),linear-gradient(to right,#FFBB70,#FF7449);
        background-clip: padding-box,border-box;
        background-origin: border-box;
    }
    //文字背景
    .bottom .check{
        width: 80px;
        height: 30px;
        font-size: 12px;
        padding-top: 0.05rem;
        text-align: center;
        line-height: 30px;
        color: #F7DFC8;
        margin-left: 42px;
        /*position: center;*/
        /*margin-top: 20%;*/
        /*margin-bottom: 20%;*/
        border-radius: 8px;
        background: linear-gradient(to right,#FFBB70,#FF7449);
    }
	.cancel{

	}
    .cancelBtn{
        /*position: absolute;*/
        /*right: 150px;*/
        height: 30px;
        width: 80px;
        padding-top: 0.05rem;
        font-size: 12px;
        line-height: 30px;
        border: 1px solid transparent;
        border-radius: 10px;
        background-image: linear-gradient(white,white),
        linear-gradient(to right,#ffc274,#ff5D3B);
        background-clip: padding-box ,border-box;
        background-origin: border-box;
        color: #ff5809;
        margin-left: 130px;
        text-align: center;
    }
    .head{
        width: 100%;
        height: 20px;
        z-index: 8;
        background: #f8f8f8;
    }
    .search{
        margin: 10px 20px 0px 20px;
        height: 35px;
        border-radius: 30px;
        background-color: #f4f4f4;
        display: flex;
        align-items: center;
    }
    .search-icon{
        width: 25px;
        height: 25px;
        margin-left: 10px;
        background-image: url("../../../assets/images/search-icon.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .navbar{
        z-index: 10;
        background-color: #f8f8f8;
    }

    input{
        font-size: 14px;
        margin-left: 15px;
        background-color: #f4f4f4;
        border: 0;
        width: 250px;
    }
    input::-webkit-input-placeholder{
        color: #d9d9d9;
    }
    .reviews{
        width: 100%;
        overflow: hidden;
        height: auto;
        position: relative;
        top: 35px;
    }
    .review{
        margin:  3px 15px 15px;
        height: auto;
        background-color: white;
        border-radius: 8px;
        overflow: hidden;
    }
    .bottom{
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;

    }
    .bottom .time{
        /*width: 180px;*/
        height: 20px;
        text-align: left;
        font-size: 14px;
        color: #999999;
        white-space: nowrap;
    }
    .addBtn{
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 70px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        button{
            width: 92%;
            height: 40px;
            border: none;
            border-radius: 8px;
            background-image: linear-gradient(to right,#7FCAFF,#3091FF);
            color: white;
            font-size: 14px;
        }
    }

</style>
